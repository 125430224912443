import openChat from '@/helpers/hubspot/openChat';
import classNames from 'classnames';
import Button from './Button';

interface ChatButtonProps {
	navOpen?: boolean;
	variant?: 'nav' | 'progress-bar';
	navDarkVariant?: boolean;
}

export const ChatButton = (props: ChatButtonProps) => {
	const { navOpen, variant = 'nav', navDarkVariant } = props;
	const isNav = variant === 'nav';
	const isProgress = variant === 'progress-bar';

	return (
		<Button
			variant={navDarkVariant ? 'tiny-transparent' : 'tiny-subtle'}
			className={classNames(
				{ 'xl:hidden': isNav },
				{ 'hidden xl:block': isProgress },
				// to hide behind open nav panel when open
				{ '-z-10': navOpen },
				'transition-all',
			)}
			onClick={openChat}
		>
			Chat with us
		</Button>
	);
};
