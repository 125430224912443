'use client';

// Modules
import { useSplitTreatments } from '@splitsoftware/splitio-react';
import { useEffect, useMemo } from 'react';
// Helpers
import { setHeapPropsClientSide } from '@/helpers/heap/setHeapPropsClientSide';
import Cookies from 'js-cookie';
import { splitSwitcher } from '@/helpers/split/splitSwitcher';
import { TreatmentsWithConfig } from '@/helpers/split/types';

/**
 * useSplitAndHeap
 *
 * 'useSplitAndHeap' is a hook that returns an SplitContext object
 * extended with a treatments property object that contains feature flag evaluations.
 * It uses the 'useSplitTreatments' hook which in turn uses the 'useSplitClient'
 * hook to access the client from the Split context, and invokes the
 * 'client.getTreatmentsWithConfig()' method if the names option is provided, or
 * the 'client.getTreatmentsWithConfigByFlagSets()' method if the flagSets option
 * is provided.
 * @param flagNames List of feature flag names to get the treatments for.
 * @returns — A Split Context object extended with a TreatmentsWithConfig instance, that might contain control treatments if the client is not available or ready, or if feature flag names do not exist.
 *
 * @example
 * const { feature_1, feature_2 } = useSplitAndHeap('feature_1', 'feature_2');
 *
 * @see https://help.split.io/hc/en-us/articles/360020448791-JavaScript-SDK#get-treatments-with-configurations
 */
export function useSplitAndHeap<T extends string>(...flagNames: T[]) {
	const result = useSplitTreatments({ names: flagNames });

	const internalSplitTreatmentValue = Cookies.get('split_treatment');

	// Memoize the internal treatments to prevent hydration mismatch
	const internalTreatments = useMemo(
		() => splitSwitcher(flagNames, internalSplitTreatmentValue ?? ''),
		[flagNames, internalSplitTreatmentValue],
	);
	const { treatments, isReady } = result;

	useEffect(() => {
		if (!isReady) return;

		// Set Heap custom props
		if (window.heap) {
			const heapUserProperties = Object.entries(treatments).reduce(
				(acc, [key, value]) => ({
					...acc,
					[`SplitTreatment ${key}`]: value.treatment,
				}),
				{},
			);
			setHeapPropsClientSide(heapUserProperties);
		}
	}, [isReady, treatments, flagNames]);

	return {
		...(internalSplitTreatmentValue
			? internalTreatments
			: (result.treatments as TreatmentsWithConfig<T>)),
		isReady: result.isReady,
	};
}
